<template>
  <section class="section">
    <div class="container">
      <div class="login-panel">
        <div class="heading text-center">
          <h1>Reset Password</h1>
        </div>
        <form
          class="form-horizontal mt-1"
          @submit.prevent="resetPassword"
        >
          <div
            class="form-group"
            :class="{ 'form-error': errors.email }"
          >
            <label class="sr-only">Email address</label>
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Email address"
            >
            <div
              v-for="(error, index) in errors.email"
              :key="index"
              class="form-error-message"
            >
              {{ error }}
            </div>
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-lg btn-block btn-primary"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import {authStore} from '@/store/__STORE_auth';
export default {
  data() {
    return {
      email: '',
      errors: [],
    };
  },
  methods: {
    resetPassword() {
      const email = this.email;
      authStore.resetPassword({email})
          .then(() => {
            // eslint-disable-next-line max-len
            this.$toast.success('If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.');
            this.$router.push('/login');
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
            if (err.response.status == 422) {
              if (err.response.data.errors) {
                this.errors = err.response.data.errors;
              } else {
                this.errors = [];
              }
            }
          });
    },
  },
};
</script>
